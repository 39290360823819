// import React from "react"

import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import Cookies from "js-cookie";

import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
// import { ApolloLink } from "apollo-link"
import { createUploadLink } from "apollo-upload-client";

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      if (message === "Not Authorized, Please login again") {
        Cookies.remove("libraryAdminToken");
        Cookies.remove("libraryAdminProfile");
        localStorage.clear();
        window.location.reload();
        return message;
      }
      return message;
    });
  }

  if (networkError) {
    // toast.error(({ closeToast }) => <Box px="3">Oops, Network error</Box>)
    console.log(networkError);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("libraryAdminToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});
// const link = ApolloLink.from([errorLink, authLink])
const link = ApolloLink.from([errorLink, authLink, uploadLink]);
// console.log(process.env);
export const client = new ApolloClient({
  cache,
  link,
});

// cache.writeData({
//   data: {
//     isLoggedIn: !!Cookies.get("x-auth"),
//   },
// })

export default client;
