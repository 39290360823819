import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Loader } from './components/ui/Loader';
import { ADMIN_ROUTES, AUTH_ROUTES } from './constants';

const Register = lazy(() => import('./views/auth/Register'))
const Login = lazy(() => import('./views/auth/Login'))

const Libraries = lazy(() => import('./views/libraries/Libraries'))
const Library = lazy(() => import('./views/libraries/Library'))
const DataOfficers = lazy(() => import('./views/data-officers/DataOfficers'))
const Admin = lazy(() => import('./views/admin/Admin'))
const Analytics = lazy(() => import('./views/analytics/Analytics'))

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path={AUTH_ROUTES.REGISTER} component={Register} />
          <Route exact path={AUTH_ROUTES.LOGIN} component={Login} />

          <Route exact path={ADMIN_ROUTES.LIBRARIES} component={Libraries} />
          <Route exact path={ADMIN_ROUTES.SINGLE_LIBRARY} component={Library} />
          <Route exact path={ADMIN_ROUTES.DATA_OFFICERS} component={DataOfficers} />
          <Route exact path={ADMIN_ROUTES.ADMIN} component={Admin} />
          <Route exact path={ADMIN_ROUTES.ANALYTICS} component={Analytics} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
