import React from "react"

// Libraries
import { Flex } from "@chakra-ui/react"

export const Loader = () => {
  return (
    <Flex justifyContent="center" alignItems="center" h="100vh">
      {/* <Image src={Droplet} alt="Urbanwash" /> */}
      <svg width="68" height="62" viewBox="0 0 68 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.55668 24.7782C8.55668 27.4043 9.18416 30.3159 10.4391 33.4558H2.22473C0.969753 33.4558 0 31.9714 0 30.1446V23.2368C0 21.4099 1.0268 19.9256 2.22473 19.9256H9.01303C8.72781 21.5241 8.55668 23.1226 8.55668 24.7782Z" fill="white" />
        <path d="M65.4299 27.1745L67.4264 33.3972H56.9873C58.2423 30.2573 58.8698 27.4028 58.8698 24.7196C58.8698 23.0641 58.6986 21.4656 58.4134 19.8671H67.4264L65.4299 27.1745Z" fill="white" />
        <path d="M11.523 35.9091C13.5195 40.0196 16.4288 44.5296 20.3649 49.3822H2.22473C0.969753 49.3822 0 47.8979 0 46.071V39.2203C0 37.3935 1.0268 35.9091 2.22473 35.9091H11.523Z" fill="white" />
        <path d="M65.4298 42.6457L67.4264 49.3822H47.0615C50.9976 44.5296 53.9069 40.0196 55.9034 35.9091H67.4264L65.4298 42.6457Z" fill="white" />
        <path d="M33.7135 0.970581C20.5933 0.970581 9.8689 11.6463 9.8689 24.8339C9.8689 31.2279 13.6909 39.1633 21.2778 48.5259C26.8111 55.3195 32.2874 60.2863 32.4585 60.4575L33.6565 61.5422L34.8544 60.4575C35.0826 60.2292 40.5018 55.3195 46.0351 48.5259C53.622 39.2204 57.444 31.2279 57.444 24.8339C57.5011 11.6463 46.8337 0.970581 33.7135 0.970581ZM33.7135 42.4173C24.0159 42.4173 16.1438 34.539 16.1438 24.8339C16.1438 15.1287 24.0159 7.25039 33.7135 7.25039C43.4111 7.25039 51.2832 15.1287 51.2832 24.8339C51.2832 34.539 43.4111 42.4173 33.7135 42.4173Z" fill="#27A5FF" />
        <path d="M33.7135 32.7121C38.0611 32.7121 41.5856 29.1849 41.5856 24.8338C41.5856 20.4827 38.0611 16.9555 33.7135 16.9555C29.3658 16.9555 25.8413 20.4827 25.8413 24.8338C25.8413 29.1849 29.3658 32.7121 33.7135 32.7121Z" fill="#FF2727" />
      </svg>
    </Flex>
  )
}