export const AUTH_ROUTES = {
  REGISTER: '/register',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/password/forgot',
  RESET_PASSWORD: '/password/reset',
}

export const ADMIN_ROUTES = {
  LIBRARIES: '/',
  ANALYTICS: '/analytics',
  SINGLE_LIBRARY: '/libraries',
  DATA_OFFICERS: '/agents',
  ADMIN: '/admins',
}